.App {
  padding: 3%;
  background: #212020;
  /* overflow: scroll; */
  color: white;
}

.hero {
  height: 100vh;
  display: grid;
  align-items: end;
  grid-template-rows: 1fr auto 1fr;
}

/* .heroText {
  display: flex;
  flex-direction: column;
} */

.heroMainBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.musicIcon {
  justify-self: center;
}

.socialMediaIcons {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, auto);
  max-width: 50%;
  padding-top: 50px;
  align-items: left;
}

.heroText h2 {
  -webkit-box-reflect: below -12px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

.heroTextSpecial {
  color: #f178b6;
  position: relative;
  display: inline-block;
  animation: animate 3s ease-in-out infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes animate {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-30px);
  }
  40%,
  100% {
    transform: translateY(0px);
  }
}

#grid > * {
  min-width: 0px;
  min-height: 0px;
  overflow: scroll;
}

.zoomable-svg,
.turn-svg,
.socialMediaIcons a {
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  transform-origin: center;
}

.zoomable-svg:hover,
.socialMediaIcons a:hover {
  transform: scale(1.3);
}

.turn-svg:hover {
  transform: rotate(360deg);
}
